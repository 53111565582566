<template>
  <a-modal :title="title" :visible="open" :confirmLoading="uploading" @cancel="summaryHandleCancel" @ok="summaryHandle">
    <a-spin tip="汇总统计中..." :spinning="uploading">
      <a-form-model-item label="汇总统计日数据" prop="summaryDate" ref="summaryFrom">
        <a-date-picker
          style="width: 30%;text-align: center;"
          placeholder="请选择日期"
          v-model="summaryDate"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          allow-clear
          :getCalendarContainer="trigger => trigger.parentNode"
        />
      </a-form-model-item>
    </a-spin>
  </a-modal>
</template>

<script>
import { summaryVehicleDailyData } from '@/api/iot/vehicleDailyData'

export default {
  name: 'SummaryMonth',
  props: {},
  components: {},
  data() {
    return {
      title: '智驾日数据汇总统计',
      open: false,
      // 是否禁用汇总
      uploading: false,
      updateSupport: 0,
      summaryDate: null
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  methods: {
    /** 窗体关闭 */
    summaryHandleCancel(e) {
      this.open = false
      this.fileList = []
      // 关闭后刷新列表
      this.$emit('ok')
    },
    /** 汇总窗体开启 */
    summaryHandleOpen(e) {
      this.open = true
    },
    /** 处理汇总统计 */
    summaryHandle() {
      this.uploading = true
      console.log('summaryDate:', this.summaryDate)
      if (!this.summaryDate) {
        this.$warning({
          title: 'Warning',
          content: '请先选择要统计的工资月份'
        })
        this.uploading = false
        return
      }
      summaryVehicleDailyData({
        summaryDate: this.summaryDate
      })
        .then(response => {
          this.fileList = []
          // this.$message.success(response.msg)
          this.$message.info(response.msg)
          this.open = false
          this.$emit('ok')
          this.uploading = false
        })
        .catch(() => {
          this.uploading = false
        })
    },
    handleCheckedUpdateSupport() {
      this.updateSupport = this.updateSupport === 0 ? 1 : 0
    }
  }
}
</script>
